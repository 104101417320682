/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
//import $ from 'jquery';
import carousel from 'vue-owl-carousel';
import axios from 'axios';
import lightbox2 from 'lightbox2';
import ClickOutside from 'vue-click-outside';
import overlayscrollbars from 'overlayscrollbars';

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

$(document).ready(function () {
    function showPopup(whichpopup) {
        var docHeight = $(document).height();
        var scrollTop = $(window).scrollTop();
        $('.overlay-bg').show().css({'height': docHeight});
        $('.popup' + whichpopup).show().css({'top': scrollTop + 20 + 'px'});
    }

    // function to close our popups
    function closePopup() {
        $('.overlay-bg, .overlay-content').hide();
    }

    $('.show-popup').click(function (event) {
        var selectedPopup = $(this).data('showpopup');
        showPopup(selectedPopup);
    });

    $('.close-btn, .overlay-bg').click(function () {
        closePopup();
    });

    $(document).keyup(function (e) {
        if (e.keyCode == 27) {
            closePopup();
        }
    });

});

const app = new Vue({
    el: '#app',

    data() {
        return {
            fieldSlidBoxShow: false,
            filterMobState: false,
            filterMobStateFlex: false,
            bodyFixState: false,
            bodyCoverFixState: false,
            popupFilterState: false,
            stateSocBlock: false,
            stateSocBlockMob: false,
            stateSettingsBlock: false,
            detailObj: '',
            productCarouseButtons: true,
            paneStatus: false,
            formStatus: false,
            info: null,
            userNumber: null,
            file: null,
            showUploadImg: false,
            changesArrayList: null,
            picked: 'master',
            slidState: false,
            categoryBoxListState: false,
            wishlist: false,
            deleteProductItem: false,
            statePlateSlid: '',
            regionNum: ''
        }
    },

    methods: {

        checkBoxLikeState(event) {
            let newAttr = '/svg/sprite.svg#heart'
            let attr = '/svg/sprite.svg#heart-simple'

            let data = {
                user_id: event.currentTarget.getAttribute('data-type'),
                item_type: event.currentTarget.getAttribute('data-user'),
                item_id: event.currentTarget.getAttribute('data-item-id')
            }

            if (event.currentTarget.childNodes[0].classList.contains('active')) {
                event.currentTarget.childNodes[0].classList.remove('active');
                event.currentTarget.childNodes[0].childNodes[0].setAttribute('xlink:href', attr);
            } else {
                event.currentTarget.childNodes[0].classList.add('active');
                event.currentTarget.childNodes[0].childNodes[0].setAttribute('xlink:href', newAttr);
            }

            axios
                .post('/api/wishlist', data)
                .then(response => {
                })
                .catch(error => {
                    this.errored = true;
                })
        },

        reportMessage() {



            let url = document.getElementById('thisUrl').value;
            let report = ''

            let elements = document.getElementsByClassName('checkbox-item__radio')

            for (let i = 0; i <= elements.length - 1; i++) {
                if (elements[i].checked) {
                    report = elements[i].value;
                }
            }

            let data = {
                url: url,
                report: report
            }

            axios
                .post('/api/reports', data)
                .then(response => {
                    if (response.data.data === 'success') {
                        document.getElementById('popup4').style.display = 'none'
                        document.getElementById('popup6').style.display = 'block'
                    }
                })
                .catch(error => {
                    this.errored = true;
                })
        },

        toggleStatePlateSlid() {
            document.querySelector('.plate__option-main').setAttribute('style', 'opacity: 1;');
            this.statePlateSlid = !this.statePlateSlid;
        },

        hideStatePlateSlid() {
            this.statePlateSlid = false
        },

        toggleStateSettingsBlock() {
            this.stateSettingsBlock = !this.stateSettingsBlock;
        },

        hideStateSettingsBlock() {
            this.stateSettingsBlock = false
        },

        toggleCategoryBoxListState() {
            this.categoryBoxListState = !this.categoryBoxListState;
        },

        hideCategoryBoxListState() {
            this.categoryBoxListState = false
        },

        toggleSlid() {
            this.slidState = !this.slidState;
        },

        hideslidState() {
            this.slidState = false
        },

        toggleFieldSlidBox() {
            this.fieldSlidBoxShow = !this.fieldSlidBoxShow;
            this.filterMobStateFlex = !this.filterMobStateFlex;
        },

        hideFieldSlidBox() {
            this.fieldSlidBoxShow = false
            this.filterMobStateFlex = false
        },

        toggleFilterMobState() {
            this.filterMobState = !this.filterMobState;
        },

        hideFilterMobState() {
            this.filterMobState = false
            this.filterMobStateFlex = false
        },

        toggleStateSocBlock() {
            this.stateSocBlock = !this.stateSocBlock;
        },

        hideStateSocBlock() {
            this.stateSocBlock = false
        },

        toggleStateSocBlockMob() {
            this.stateSocBlockMob = !this.stateSocBlockMob;
        },

        hideStateSocBlockMob() {
            this.stateSocBlockMob = false
        },

        showPane(event) {
            this.paneStatus = !this.paneStatus;
            this.detailObj = event.target;
        },

        hideShowPane(event) {
            this.paneStatus = false;
        },

        setStateGreen() {
            this.detailObj.classList.remove('detail_red');
            this.detailObj.classList.remove('detail_yellow');
            this.detailObj.classList.remove('detail_green');
            this.detailObj.classList.add('detail_green');

            this.registerChanges()
        },

        setStateRed() {
            this.detailObj.classList.remove('detail_yellow');
            this.detailObj.classList.remove('detail_green');
            this.detailObj.classList.add('detail_red');

            this.registerChanges()
        },

        setStateYellow() {
            this.detailObj.classList.remove('detail_red');
            this.detailObj.classList.remove('detail_yellow');
            this.detailObj.classList.remove('detail_green');
            this.detailObj.classList.add('detail_yellow');

            this.registerChanges()
        },


        registerChanges() {
            let details = document.getElementsByClassName('detail');
            let changesArray = [];
            var changesArrayList = new Map();
            for(var i = 0; i < details.length; i++) {
                var id = details[i].id;
                var value = details[i].className.baseVal;
                var value = value.replace('detail','')
                var value = value.replace(' ','')
                changesArrayList.set(id, value);
            }

            this.changesArrayList = JSON.stringify([...changesArrayList]);
        },

        togglePopupFilter(e) {
            this.popupFilterState = !this.popupFilterState;
            this.bodyCoverFixState = !this.bodyCoverFixState;
            e.preventDefault();
        },

        hidePopupFilterState() {
            this.popupFilterState = false
            this.bodyCoverFixState = false
        },

        testNavbar() {
            this.bodyFixState = !this.bodyFixState;
        },

        submitTheForm(event) {
            let number = document.getElementById('user_phone').value;

            axios
                .get('/api/check_number/' + number)
                .then(response => {
                    this.info = response.data.response;

                    if (this.info === 'Allowed') {
                        this.formStatus = true;
                        this.userNumber = number;
                    }

                    if(this.info === 'Denied') {
                        let url = 'http://masinbazari.az/additional_car/'+number;
                        window.location.href = url
                    }
                })
                .catch(error => {
                    this.errored = true;
                })
        },

        submitTheFormUnlimit(event) {
            let number = document.getElementById('user_phone').value;

            axios
                .get('/api/check_number/' + number)
                .then(response => {
                    this.info = response.data.response;

                    if (this.info === 'Allowed' || this.info === 'Denied') {
                        this.formStatus = true;
                        this.userNumber = number;
                    }
                })
                .catch(error => {
                    this.errored = true;
                })
        },

        radioHandler(event) {
            this.picked = event.target.id;
        },

        handleFileUpload(event) {
            this.userNumber = document.getElementById('user_phone').value;
            let id = event.target.id;
            localStorage.setItem('id', id);
            this.file = document.getElementById(id).files[0];

            let formData = new FormData();
            let coolest_badass_id = document.getElementById('coolest_badass_id').value;
            formData.append('file', this.file);
            formData.append('number', coolest_badass_id);

            axios
                .post('/api/save_images',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'X-CSRF-TOKEN': $('meta[name="token"]').attr('value')
                        }
                    }
                )
                .then(response => {
                    let id = localStorage.getItem('id')

                    if (id === 'uploader_imgfront') {
                        document.getElementById('img_front').src = response.data.data.compressed_image
                    }

                    if (id === 'uploader_imgBack') {
                        document.getElementById('img_back').src = response.data.data.compressed_image
                    }

                    if (id === 'uploader_imgProfile') {
                        document.getElementById('img_profile').src = response.data.data.compressed_image
                    }

                    if (id === 'uploader_imgSalon') {
                        document.getElementById('img_salon').src = response.data.data.compressed_image
                    }

                    let image_div_id = id + '_div';
                    document.getElementById(image_div_id).classList.add('show_upload_img');
                    this.showUploadImg = true;
                })
                .catch(response => {
                    console.log('FAILURE!!');
                })
        },

        turnImg(event) {
            console.log(event.path[2].childNodes[0].childNodes[2].childNodes[0].src)
            console.log(11)

            let src = event.path[2].childNodes[0].childNodes[2].childNodes[0].src;
            let data = {
                'filename': src
            };
            axios.post('/api/rotate_api', data)
                .then(response => {
                    this.info = response.data.data;
                    event.path[2].childNodes[0].childNodes[2].childNodes[0].src = '';
                    event.path[2].childNodes[0].childNodes[2].childNodes[0].setAttribute('src', response.data.data + "?" + new Date().getTime());
                })
                .catch(response => {
                    this.errored = true;
                })
        },

        deleteImg(event) {
            let src = event.path[2].childNodes[0].childNodes[2].childNodes[0].src;
            let data = {
                'filename': src,
            };

            axios.post('/api/delete_api', data)
                .then(response => {
                    this.info = response;
                    event.target.parentNode.parentNode.classList.remove('show_upload_img');

                })
                .catch(error => {
                    this.errored = true;
                })
        },

        // turnImg(event) {
        //     console.log(event.path[2].childNodes[0].childNodes[2].childNodes[0].src)
        //
        //     let src = event.target.parentNode.parentNode.childNodes[0].childNodes[2].childNodes[0].src;
        //     let data = {
        //         'filename': src
        //     };
        //     axios.post('/api/rotate_api', data)
        //         .then(response => {
        //             this.info = response.data.data;
        //             event.target.parentNode.parentNode.childNodes[0].childNodes[2].childNodes[0].src = '';
        //             event.target.parentNode.parentNode.childNodes[0].childNodes[2].childNodes[0].setAttribute('src', response.data.data + "?" + new Date().getTime());
        //         })
        //         .catch(response => {
        //             this.errored = true;
        //         })
        // },

        // deleteImg(event) {
        //     let src = event.target.parentNode.parentNode.childNodes[0].childNodes[2].childNodes[0].src;
        //     let data = {
        //         'filename': src,
        //     };
        //
        //     axios.post('/api/delete_api', data)
        //         .then(response => {
        //             this.info = response;
        //             event.target.parentNode.parentNode.classList.remove('show_upload_img');
        //
        //         })
        //         .catch(error => {
        //             this.errored = true;
        //         })
        // },

        multiHandleFileUpload(event) {
            this.userNumber = document.getElementById('user_phone').value;
            let node = document.querySelector('.uploader-item_last')
            let fileInput = document.getElementById('uploader_imgages');
            let fileList = [];

            for (let i = 0; i <= fileInput.files.length - 1; i++) {
                fileList.push(fileInput.files[i]);
            }
            let number = document.getElementById('user_phone').value;
            fileList.forEach(function(file) {

                let formData = new FormData();

                formData.append('file', file);
                formData.append('number', number);

                axios
                    .post(  '/api/save_images',
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'X-CSRF-TOKEN': $('meta[name="token"]').attr('value')
                            }
                        }
                    )
                    .then(response => {
                        node.insertAdjacentHTML('beforebegin', `<div class="uploader-item" id="item-${response.data.data.id}">
                                <div class="uploader-item__box">
                                    <div class="uploader-item__wrap show_upload_img" id="uploader_imgfront_div_${response.data.data.id}">
                                        <label class="uploader-item__box-inner upload-img" id="upload-${response.data.data.id}">

                                            <div class="uploader-item__img-frame">
                                                <img 
                                                     alt=""
                                                     class="uploader-item__img"
                                                     src="${response.data.data.compressed_image}"
                                                     id="img_item-${response.data.data.id}"
                                                >
                                            </div>

                                            <input class="d-none"
                                                   type="file"
                                                   id="multi_uploader_imgfront_${response.data.data.id}"
                                            >
                                        </label>

                                        <div class="uploader-item__wrap-icons">
                                            

                                            <svg id="rotate-${response.data.data.id}" class="uploader-item__icon uploader-item__icon_turning">
                                                <use xlink:href="/svg/sprite.svg#turning"></use>
                                            </svg>

                                            <svg id="delete-${response.data.data.id}" class="uploader-item__icon uploader-item__icon_delete added_delete">
                                                <use xlink:href="/svg/sprite.svg#basket"></use>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>`);

                        let uploadImgId = document.getElementById(`upload-${response.data.data.id}`)
                        uploadImgId.addEventListener('change', function(e){
                            multiUploadImgId(e)
                        })

                        let myId = document.getElementById(`delete-${response.data.data.id}`)
                        myId.addEventListener('click', function(e){
                            multiDeleteImg(`item-${response.data.data.id}`)
                        })

                        let rotateId = document.getElementById(`rotate-${response.data.data.id}`)
                        rotateId.addEventListener('click', function(e){
                            rotateImg(`item-${response.data.data.id}`)
                        })

                    })
                    .catch(response => {
                        console.log('FAILURE!!');
                    })
            });
        },

        selectMark(event) {
            let prev = document.getElementsByClassName('select__option_hide');
            for(let i = 0; i < prev.length; i++) {
                prev[i].classList.remove('d-block')
            }

            let elements = document.getElementsByClassName('select__option_hide ' + event.target.value);
            for(let i = 0; i < elements.length; i++) {
                elements[i].removeAttribute('selected');
                if (i === 0) {
                    elements[0].setAttribute('selected', 'selected')
                }

                elements[i].className += ' d-block';
                elements[i].style.display = 'none';
            }
        },

        changedProductCarousel(event) {
            let items = event.item.count;
            if (items >= 5) {
                this.productCarouseButtons = true;
            }
        },

        changeSlideProductImg(event) {
            let el = document.getElementById('product-media__img');
            el.src = event.target.firstElementChild.src
        },

        changeSlideProductImgThumb(event) {
            let el = document.getElementById('product-media__img');
            el.src = event.target.src
        },


        deleteProduct(event) {
            axios.post('/assets/test.json')
                .then(response => {
                    if (response.data.delete === 'true') {
                        this.deleteProductItem = true
                        if (this.deleteProductItem === true) {
                            event.path[6].remove();
                        }
                    }
                })
                .catch(error => {
                    this.errored = true;
                })
        },

        changed(event) {
            console.log('event.target', event)

            let curentIndex = event.item.index;
            let el = event.currentTarget.children[0].children[0].children[curentIndex]
            let elImg = el.children[0].children[0]

            console.log(elImg)
            let elImgDataSrc = elImg.getAttribute('data-src')

            elImg.setAttribute('src', elImgDataSrc)
            elImg.setAttribute('style', 'display: block!important;')
        },

        changeImgFeature(event) {
            let span = document.getElementById("upload_image-name")
            span.innerHTML = 'Seçilmişdir';
        }
    },




    components: {
        carousel: carousel,
    },

    directives: {
        ClickOutside
    },

});

function multiUploadImgId(e) {
    e.preventDefault()
   // document.getElementById('uploader_imgages').click();
}

function multiDeleteImg(id) {
    let src = document.getElementById('img_'+id).src;
    document.getElementById(id).style.display = 'none'

    let data = {
        'filename': src
    };

    axios.post('/api/delete_api', data)
        .then(response => {


        })
        .catch(error => {
            this.errored = true;
        })
}

function rotateImg(id) {
    console.log(27)
    let src = document.getElementById('img_'+id).src;
    console.log(src)

    let data = {
        'filename': src
    };

    axios.post('/api/rotate_api', data)
        .then(response => {
            document.getElementById('img_'+id).setAttribute('src', response.data.data + "?" + new Date().getTime());
        })
        .catch(response => {
           console.log(response)
        })
}

document.addEventListener("DOMContentLoaded", function() {
    OverlayScrollbars(document.querySelectorAll('.plate-slid'), { });
});

document.addEventListener("DOMContentLoaded", function() {
    OverlayScrollbars(document.querySelectorAll('.product-info-content__text.scroll'), { });
});


